import {
  apiPcNotifyList,
  apiPCNotifyAllRead,
  apiPcNotifyDelete
} from '@/api/api.js'
export default {
  components: {},
  data() {
    return {
      //loading: true,
      resultData: [],
      page: 1,
      loading: false,
      noMore: false,
      noData: false,
      frist: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.resultData = []
      this.getNotifyList()
    },
    getNotifyList() {
      if (this.noMore) return
      this.loading = true
      let paramsBase = {
        cate: 2,
        page: this.page
      }
      apiPcNotifyList(paramsBase).then((res) => {
        if (res.data.length == 0 && this.frist) {
          this.frist = false
        }
        let dataValue = res.data.list
        this.loading = false
        if (dataValue.length != 0) {
          setTimeout(() => {
            dataValue.forEach((element) => {
              this.resultData.push(element)
            })
          }, 500)
        } else {
          this.loading = false
          this.noMore = true
        }

        if (res.data.length == 0) {
          this.noData = true
        }
      })
    },
    clickSeeDetails(item) {
      sessionStorage.setItem('tcl_Ask_id', item.id)
      this.$router.push({ name: 'InformationTeacherMessageDetails' })
    },
    handleQuesDetail(item) {
      this.$router.push({
        name: 'Exercises',
        query: {
          clid: item.questioninfo.clid,
          practiceId: item.questioninfo.qid,
          from: 'message'
        }
      })
    },
    load() {
      this.page++
      this.getNotifyList()
    },
    handleAllRead() {
      apiPCNotifyAllRead().then((res) => {
        this.$message.success('全部已读设置成功')

        this.page = 1
        this.init()
      })
    },
    handleDelete(item) {
      apiPcNotifyDelete({
        id: item.id
      }).then(() => {
        this.$message.success('删除通知成功')

        this.page = 1
        this.init()
      })
    }
  }
}
